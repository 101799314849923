<template>
  <div class="personal">
    <div class="title">
      <div class="tit_info">
        <span>工作证明:</span>
        工作证明拍照
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 1)" :fileList="socialInsuranceAccountScreenshotUrlList"  @ondelete="(i) => ondelete(i, 1)"></Upload-item>
    </div>

    <div class="title">
      <div class="tit_info">
        <span>个人学位证明:</span>
        毕业证/学位证(可登陆学信网截图)
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 2)"  :fileList="userDegreeCertificateUrlList"   @ondelete="(i) => ondelete(i, 2)"></Upload-item>
    </div>

    <div class="title">
      <div class="tit_info">
        <span>芝麻信用分:</span>
        芝麻信用分照片
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 3)"   :fileList="sesameCreditScoreUrlList" @ondelete="(i) => ondelete(i, 3)"></Upload-item>
    </div>

    <div class="title">
      <div class="tit_info">
        <span>工资发放流水截图:</span>
        最近一个月工资流水证明
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 4)"   :fileList="wagesScreenshotUrlList"  @ondelete="(i) => ondelete(i, 4)"></Upload-item>
    </div>

 

    <div class="title">
      <div class="tit_info">
        <span>其他:</span>
        其他证明材料
      </div>
      <Upload-item @handleChange="(val) => importFile(val, 5)"  :fileList="otherCertificateUrlList"  @ondelete="(i) => ondelete(i, 5)"></Upload-item>
    </div>

    <div class="nextstep" v-if="source == 'UserOrder'" @click="supplemententerprise">提交补充资料</div>
    <div class="nextstep" v-if="source == 'User'" @click="onSubmitOrder">提交</div>
    <div class="nextstep" v-if="source == 'placeOrder'" @click="onSubmitted">提交审核立即租赁</div>
  </div>
</template>
<script>
import {saveSupplementaryInfo, userDetail} from '@/api/userInfoPort/index'
export default {
  components: {},
  props: ['source'],
  data() {
    return {
      socialInsuranceAccountScreenshotUrlList: [],
      userDegreeCertificateUrlList: [],
      sesameCreditScoreUrlList: [],
      wagesScreenshotUrlList: [],
      otherCertificateUrlList: []
    }
  },
  watch: {},
  created() {
    this.getapiUserInfodetail()
    
  },
  methods: {
    importFile(val, type) {
      if (type == 1) {
        this.socialInsuranceAccountScreenshotUrlList.push(val)
      }
      if (type == 2) {
        this.userDegreeCertificateUrlList.push(val)
      }
      if (type == 3) {
        this.sesameCreditScoreUrlList.push(val)
      }
      if (type == 4) {
        this.wagesScreenshotUrlList.push(val)
      }
      if (type == 5) {
        this.otherCertificateUrlList.push(val)
      }
    },
    ondelete(i, type) {
      if (type == 1) {
        this.socialInsuranceAccountScreenshotUrlList.splice(i, 1)
      }
      if (type == 2) {
        this.userDegreeCertificateUrlList.splice(i, 1)
      }
      if (type == 3) {
        this.sesameCreditScoreUrlList.splice(i, 1)
      }
      if (type == 4) {
        this.wagesScreenshotUrlList.splice(i, 1)
      }
      if (type == 5) {
        this.otherCertificateUrlList.splice(i, 1)
      }
    },

    // 查看个人资料
    getapiUserInfodetail() {
      userDetail().then(res => {
        if (res.data.code == 200) {
          this.socialInsuranceAccountScreenshotUrlList = res.data.data.socialInsuranceAccountScreenshotUrlList
          this.userDegreeCertificateUrlList=res.data.data.userDegreeCertificateUrlList
          this.sesameCreditScoreUrlList = res.data.data.sesameCreditScoreUrlList
          this.wagesScreenshotUrlList = res.data.data.wagesScreenshotUrlList
          this.otherCertificateUrlList = res.data.data.otherCertificateUrlList
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
      
    },
    onSubmitted(){
      var currentCommodityDetail = JSON.parse(localStorage.getItem('currentCommodityDetail'))
      var data = {
        adChannel:currentCommodityDetail.adChannel ,
        commodityNumber: currentCommodityDetail.commodityNumber,
        expectDeliveryDate: currentCommodityDetail.expectDeliveryDate,
        isEnterpriseOrder: currentCommodityDetail.isEnterpriseOrder,
        lat: '',
        leasePatternId:currentCommodityDetail.leasePatternId,
        lng: '',
        orderRemark:currentCommodityDetail.orderRemark ,
        shareOpenId: currentCommodityDetail.shareOpenId,
        skuId: currentCommodityDetail.commoditySkuId
      }
      this.$axios.post('/zuwu-api/web/order/add/order/userLeasePattern', data).then((res) => {
        if (res.data.code == 200) {
          this.supplemententerprise()
          this.$router.push({ path: '/Submitted', query: { orderId: res.data.data.orderId }  })
        } else {
          this.$message.error(res.data.msg)
        }
      })
      .catch(() => {
        this.$message.error('请重新下单')
      });
    },
    //补充资料
    supplemententerprise() {
      var data = {
        premisesUrlList: this.premisesUrlList,
        capitalFlowUrlList: this.capitalFlowUrlList,
        officeLeaseContractUrlList: this.officeLeaseContractUrlList,
        otherCertificateUrlList: this.otherCertificateUrlList,
        paymentCertificateUrlList: this.paymentCertificateUrlList,
        socialInsurancePaymentCertificateUrlList: this.socialInsurancePaymentCertificateUrlList,
        userId: this.userId,
        orderId: this.orderId
      }
      saveSupplementaryInfo(data).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    // 添加补充资料
    onSubmitOrder() {
      var data = {
        premisesUrlList: this.premisesUrlList,
        capitalFlowUrlList: this.capitalFlowUrlList,
        officeLeaseContractUrlList: this.officeLeaseContractUrlList,
        otherCertificateUrlList: this.otherCertificateUrlList,
        paymentCertificateUrlList: this.paymentCertificateUrlList,
        socialInsurancePaymentCertificateUrlList: this.socialInsurancePaymentCertificateUrlList
      }
      this.$axios
        .post('/zuwu-api/web/enterprise/addSupplementInfo', data)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }
  }
}
</script>

<style scoped>
.personal {
  background: #fff;
  margin: 0 auto 30px;
  padding: 30px 50px 50px;
}
.personal .title {
  width: 100%;
  overflow: hidden;
  padding: 20px 10px;
}
.personal .title + .title {
  border-top: 1px dashed #999;
}
.personal .tit_info {
  font-size: 14px;
  color: #666;
  text-align: left;
  padding-bottom: 10px;
}
.personal .tit_info span {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-right: 10px;
}

.nextstep {
  width: 300px;
  height: 50px;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  background: #1e95d4;
  font-size: 18px;
  line-height: 50px;
}
</style>